<template>
  <div class="newest-wrap">
    <div v-if="!checkLoading && !showErrPage">
      <div class="newest-header">
        <span>{{ $t('app.sidebar.global') }}</span>
        <!-- <span class="err-hash" v-if="gid === 4 && err_hash_num" @click="toHash"><svg-icon name="info"></svg-icon>{{$t('taskhub.history.err_hash')}}</span> -->
      </div>
      <div class="newest-content">
        <div class="newest-content-header">
          <switch-tab :list="switchList" :val="history.tasktype" @change="handleChangeTaskType" />
          <filter-option v-if="isMounted" pageType="public_filter" isGlobal :extraFilters="extraFilters"></filter-option>
        </div>
        <div id="filterWrapper"></div>
        <Loading :loading="loading" v-if="loading" />
        <TemplateList
          v-else
          :columnAttr="columnAttr"
          :dataList="historyList"
          :isLoading="loading"
          :header="false"
          isGlobal
          :hasCheckbox="false"
          :customHeader="true"
          class="history-list"
          :page="page"
          @public="handlePublic"
          @deleteFailed="id => deleteFailed(id)"
        >
        </TemplateList>
      </div>
      <div class="pagination-wrap">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
          :page-size="pageSize"
          customTotal
        />
      </div>
      <backTo-top isContainer=".router-view__wrap"></backTo-top>
      <el-dialog
        v-model="dialogVisible"
        :title="$t('taskhub.history.delete.title')"
        width="30%"
      >
        <div class="deleteContent">
          <svg-icon name="info"></svg-icon>
          <i18n-t keypath="taskhub.history.delete.warn" tag="span">
            <template #html>
              <font :color="$isDark() ? 'white' : 'black'">{{checklist.length > 50 ? 50 : checklist.length}}</font>
            </template>
          </i18n-t>
          <!-- <span style="line-height: 21px;">任务删除操作不可恢复，任何人将不再可见所选任务报告的所有内容，请确认是否删除 <font color="white">{{checklist.length > 50 ? 50 : checklist.length}}</font> 个任务？</span> -->
        </div>
        <div class="delete-tips" v-if="checklist.length > 50">{{$t('taskhub.history.delete.tips')}}</div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">{{$t('dialog.cancel')}}</el-button>
            <el-button type="primary" @click="deleteReportFn"
              >{{$t('dialog.delete.confirm')}}</el-button
            >
          </span>
        </template>
      </el-dialog>
      <el-dialog
        v-model="failedVisible"
        :title="$t('taskhub.history.failed.title')"
        width="30%"
      >
        <div class="deleteContent">
          <svg-icon name="info"></svg-icon>
          <span style="line-height: 21px;">{{$t('taskhub.history.failed.info')}}</span>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="failedVisible = false">{{$t('dialog.cancel')}}</el-button>
            <el-button type="primary" @click="deleteReportFn('failed')"
              >{{$t('dialog.delete.confirm')}}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <Loading :loading="checkLoading" v-if="checkLoading" />
    <div class="close-captch__wrap" v-if="showErrPage">
      <close-captch :showFooter="false"></close-captch>
    </div>
  </div>
</template>

<script>
import closeCaptch from '@/components/common/closeCaptch.vue'
import TemplateList from '@/components/common/TemplateList'
import Pagination from '@/components/common/Pagination'
import { getGlobalTasks } from 'services/public'
import { checkLogin } from 'services/auth'

import { mapMutations, mapGetters } from 'vuex'
import filterOption from '../component/filter'
import SwitchTab from '../component/SwitchTab.vue'
import emitter from '@/app/emitter.js'
import { t } from 'app/i18n'
import dayjs from 'dayjs'

// import list from './data.json'
export default {
  name: 'History',
  components: {
    TemplateList,
    Pagination,
    SwitchTab,
    filterOption,
    closeCaptch
  },
  data () {
    return {
      totalSize: 0,
      pageSize: 50,
      currentPage: Number(this.$route.query.page) || 1,
      historyList: [],
      loading: true,
      isMounted: false,
      showErrPage: false,
      checkLoading: true,
      switchList: [
        {
          label: t('taskhub.history.all'),
          value: ''
        },
        {
          label: t('taskhub.history.file'),
          value: 'file'
        },
        {
          label: 'URL',
          value: 'url'
        }
      ],
      columnAttr: [
        {
          label: '环境_时间',
          props: 'env_time',
          span: 5
        },
        {
          label: '类型_分值',
          props: 'type_score',
          span: 3
        },
        {
          label: '名称_类型_标签',
          props: 'name_type_tags',
          span: 5
        },
        {
          label: '哈希',
          props: 'hash',
          span: 6
        },
        {
          label: '归属',
          props: 'owner',
          span: 3
        },
        {
          label: '下载',
          props: 'action',
          span: 2
        }
      ],
      extraFilters: {
        label: '任务状态',
        type: 'tab',
        option: [
          {
            label: '全部',
            value: 1
          },
          {
            label: '已完成',
            value: 2
          },
          {
            label: '排队中',
            value: 3
          },
          {
            label: '调度中',
            value: 4
          },
          {
            label: '检测中',
            value: 5
          },
          {
            label: '静态检测中',
            value: 6
          },
          {
            label: '动态监测中',
            value: 7
          },
          {
            label: '检测完成',
            value: 8
          },
          {
            label: '检测失败',
            value: 9
          }
        ]
      },
      // initInterval: null,
      history: {
        score: '',
        tag: '',
        tasktype: '',
        filetype: '',
        hash: ''
      },
      page: 'history',
      isFilter: false,
      checklist: [],
      filter: {},
      dialogVisible: false,
      failedVisible: false,
      failedId: 0
      // err_hash_num: 0
    }
  },
  computed: {
    ...mapGetters({
      store_tasktype: 'history/tasktype',
      store_score: 'history/score',
      store_tag: 'history/tag',
      store_filetype: 'history/filetype',
      store_hash: 'history/hash',
      gid: 'gid'
    }),
    canShare () {
      return this.checklist.some(item => !item.is_public)
    },
    canCancelShare () {
      return this.checklist.some(item => item.is_public)
    },
    disableShareTeam () {
      return this.checklist.length !== 1
    },
    teamIdsOfTask () {
      const taskIdsSharedMap = {}
      this.checklist.map(item => {
        taskIdsSharedMap[item.id] = item.team_id
      })
      return taskIdsSharedMap
    }
  },
  methods: {
    ...mapMutations({
      setOptions: 'history/SET_OPTIONS'
    }),
    // toHash() {
    //   const routeData = this.$router.resolve({ name: 'err-hash' })
    //   window.open(routeData.href, '_blank')
    // },
    modifyTeamIdsOfTask(item, val) {
      if (val) {
        for (let i = 0; i < this.checklist.length; i++) {
          this.checklist[i].team_id.push(item.team_id)
        }
      } else {
        // this.checklist.map(v => v.team_id.filter(m => m === item.team_id))
        this.checklist.map(v => {
          v.team_id = v.team_id.filter(m => m !== item.team_id)
        })
      }
    },
    handleDelete() {
      if (!this.checklist.length) return
      this.dialogVisible = true
    },
    handleChangeTaskType(type) {
      this.history.tasktype = type
      this.setOptions({ tasktype: type })
      this.handleCurrentChange(1)
    },
    handlePopoverClose () {
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.getGlobalTasks()
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.getGlobalTasks()
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, page } })
    },
    async getGlobalTasks (needloading) {
      if (needloading !== false) {
        this.loading = true
      }
      await getGlobalTasks({
        page: this.currentPage,
        size: this.pageSize,
        category: this.store_tasktype ? this.store_tasktype : undefined,
        ...this.filter,
        task_type: this.filter.task_type && typeof this.filter.task_type === 'string' ? [this.filter.task_type] : this.filter.task_type,
        os_env: this.filter.os_env && typeof this.filter.os_env === 'string' ? [this.filter.os_env] : this.filter.os_env,
        tag: this.filter.tag && typeof this.filter.tag === 'string' ? [this.filter.tag] : this.filter.tag,
        submitter_id: undefined
      })
        .then(res => {
          this.historyList = res.task
          this.totalSize = res.total
          // this.err_hash_num = res.hash_error_total
          if (needloading !== false) {
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          if (err.message === 'Request failed with status code 401') {
            this.showErrPage = true
          }
        })
    },
    init () {
      this.getGlobalTasks()
    },
    getLoginStatus(flag) {
      checkLogin().then((res) => {
        this.checkLoading = false
        if (!res.is_login) {
          this.showErrPage = true
        } else {
          if (this.$store.getters.userInfo.is_up) {
            this.showErrPage = false
            // this.getGlobalTasks()
          } else {
            this.router.replace({
              name: 'Home'
            })
          }
          // this.getGlobalTasks()
        }
      })
    }
  },
  created () {
    // this.initInterval = setInterval(() => {
    //   this.getGlobalTasks(false)
    // }, 120000)
  },
  mounted () {
    this.getLoginStatus()
    this.isMounted = true
    const that = this
    emitter.on('public_filter/filterChange', (v) => {
      if (v.filter_start_time) {
        this.filter = {
          ...v,
          filter_start_time: undefined,
          filter_end_time: undefined,
          added_on_before: dayjs(v.filter_end_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
          added_on_after: dayjs(v.filter_start_time * 1000).format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        this.filter = v
      }
      this.getGlobalTasks()
    })
    emitter.on('unlogin', () => {
      this.loading = false
    })
    this.$emitter.on(`${this.page}/checkedRowList`, (list) => {
      that.checklist = list
    })
    emitter.on('reloadResult', () => {
      if (!this.$store.getters.userInfo.is_up) {
        this.$router.replace({ name: 'Home' })
      } else {
        this.showErrPage = false
        this.checkLoading = false
      }
      // this.getGlobalTasks()
    })
  },
  unmounted () {
    // clearInterval(this.initInterval)
    this.$emitter.off(`${this.page}/checkedRowList`)
    emitter.off('reloadResult')
    emitter.off('public_filter/filterChange')
  }
}
</script>

<style lang="scss" scoped>
.newest-wrap {
  @import '@/styles/common.scss';
  min-height: calc(100vh - $footer-height - $header-height);
  .close-captch__wrap {
    min-height: calc(100vh - 116px);
    display: flex;
    align-items: center;
  }
  .newest-header {
    line-height: 46px;
    height: 46px;
    padding: 2px 24px;
    display: flex;
    span {
      font-size: 18px;
      color: var(--color-text-1);
      font-weight: 500;
      margin-right: 8px;
    }
    .err-hash {
      color: var(--color-text-2);
      cursor: pointer;
      font-size: 13px;
      font-weight: normal;
      position: relative;
      top: 1px;
      svg {
        color:#fa8c16;
        margin-right: 4px;
      }
    }
    .newest-header-info {
      font-size: 14px;
    }
  }
  .newest-content {
    padding: 0px 24px 6px;
    // height: calc(100vh - 278px);

    display: flex;
    flex-direction: column;
    .newest-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0px 0px 6px 0;
      position: relative;
      :deep(.public-list-option) {
        position: absolute;
        top: 1px;
        right: 0;
      }
    }
  }
  .pagination-wrap {
    padding: 10px 3px 24px;
    text-align: right;
  }
  .list-pagination {
  }
}
.history-list {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .history-list-btn-wrap {
      .btn-item {
        width: 88px;
        height: 32px;
        box-sizing: border-box;
        border: none;
        background: transparent;
        border-radius: 4px;
        color: var(--color-primary);
        cursor: pointer;
        &:focus {
          outline: 0;
        }
        &:not(:last-child) {
          margin-right: 8px;
        }
        &.disable {
          color: var(--color-text-2);
          cursor: not-allowed;
        }
      }
    }
    .history-list-option {
      position: relative;
      top: 2px;
      display: flex;
      align-items: center;
      .putlic-list-tag {
        display: inline-block;
        padding: 0 27px 0 10px;
        margin-right: 6px;
        color: var(--color-text-1);
        height: 26px;
        line-height: 26px;
        box-sizing: border-box;
        font-size: 14px;
        background: #364984;
        border-radius: 4px;
        position: relative;
        &-name {

        }
        &-close {
          position: absolute;
          right: 10px;
          top: -1px;
          bottom: 0px;
          margin: auto;
          cursor: pointer;
        }
      }
      .option-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }
}
.deleteContent {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
    // position: relative;
    // top: -3px;
  }
  span {
    line-height: 21px;
  }
}
.delete-tips {
  padding: 21px 0 0 21px;
  font-size: 13px;
  color: #fa8c16;
}
</style>
